import React, {useState} from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import apppstore from "../assets/appstore.png";
import playstore from "../assets/playstore.png";
import screenshot from "../assets/Screenshot.png";
import fastDelivery from "../assets/fastDelivery.jpg"
import wideVarity from "../assets/wideVarity.jpg"
import easyPayment from "../assets/easyPayment.jpg"
import support from "../assets/support.jpg"
import './MainPage.css'
import fb from "../assets/fb.png"
import messenger from "../assets/messenger.png"
import whattsapp from "../assets/WhatsApp.png"
import insta from "../assets/insta.png"
function MainPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for menu toggle

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu visibility
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="App">
      {/* Header Section */}
      <header className="App-header">
        <div className="logo-container">
          <img src={logo} alt="Chulesi Logo" className="logo" />
        </div>
        <nav className={`navbar ${isMenuOpen ? "open" : ""}`}>
          <ul>
            <li><a href="#about-us" onClick={closeMenu}>Our Features</a></li>
            <li><a href="#contact-us" onClick={closeMenu}>Contact Us  </a></li>
            <li><a href="#download-app" onClick={closeMenu} >Download App</a></li>
          </ul>
        </nav>
        {/* Hamburger Icon */}
        <div className="hamburger" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </header>

      {/* Main Content */}
      <main>
        <section id="download-app" className="cta-section">
          <div className="cta-left">
            <h2>Get the Chulesi App</h2>
            <p><b>Order delicious, freshly prepared food delivered right to your door with Chulesi! Explore a wide variety of local and international cuisines from the comfort of your phone. Chulesi’s easy-to-use app makes it simple to browse, order, and enjoy mouthwatering dishes whenever and wherever you want.</b></p>
            <div className="app-store-links">
              <a href="https://play.google.com/store/apps/details?id=com.chulesi" target="_blank" rel="noopener noreferrer">
                <img src={playstore} alt="Google Play Store" className="store-icon" />
              </a>
              <a href="https://apps.apple.com/us/app/chulesi/idXXXXXXX" target="_blank" rel="noopener noreferrer">
                <img src={apppstore} alt="Apple App Store" className="store-icon" />
              </a>
            </div>
          </div>
          <div className="cta-right">
            <img src={screenshot} alt="Chulesi App Screenshot" className="app-screenshot" />
          </div>
        </section>

        {/* Features Section */}
        <section id="about-us" className="features-section">
          <h2>Why Choose Chulesi?</h2>
          <div className="features-cards">
            <div className="card">
              <img src={fastDelivery}alt="Fast Delivery" />
              <h3>Fast Delivery</h3>
              <p>Get your food delivered in minutes with our reliable service.</p>
            </div>
            <div className="card">
              <img src={wideVarity} alt="Wide Variety" />
              <h3>Wide Variety</h3>
              <p>Choose from a wide range of cuisines and dishes.</p>
            </div>
            <div className="card">
              <img src={easyPayment} alt="Easy Payment" />
              <h3>Easy Payment</h3>
              <p>No payment hassle, cash on delivery</p>
            </div>
            <div className="card">
              <img src={support} alt="24/7 Support" />
              <h3>24/7 Support</h3>
              <p>Our customer support is available around the clock to help you.</p>
            </div>
          </div>
        </section>

        {/* Contact Us Section */}
        <section id="contact-us" className="contact-section">
          <h2>Contact Us</h2>
          <p>If you have any questions, feel free to reach out to us!</p>
          <p>Email: <a href="mailto:contact@chulesi.com">contact@chulesi.com</a></p>
          <p>Phone: +977 9862905048, +977 9861621039</p>
          <p id="roshan"> +977 9821101186, +977 9746299831</p>
          <div className="Social">
          <img src={fb} alt="Facebook"className="socialMedia" />
          <img src={messenger} alt="Messenger"className="socialMedia" />
          <img src={whattsapp} alt="WhatsApp"className="socialMedia" />
          <img src={insta} alt="Instagram"className="socialMedia" />
          </div>
        </section>
        <li><Link to="/privacy-policy" >Privacy Policy</Link></li>
        <li><Link to="/about-us" >About Us</Link></li>
        <li><Link to="/terms-and-condition" >Terms and condition</Link></li>
        <li><Link to="/faq-page" >FAQ Page</Link></li>
      </main>
    </div>
  );
}

export default MainPage;
