import React from 'react';
import './PrivacyPolicy.css'

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p><strong>Privacy Policy for Chulesi</strong></p>
      <p><strong>Last Updated:</strong> November 13, 2024</p>

      <h2>Introduction</h2>
      <p>Chulesi ("we," "us," or "our") operates the Chulesi mobile application (the "Service"). This Privacy Policy informs you of our policies regarding the collection, use, and disclosure of personal information when you use our Service and your rights regarding your data.</p>
      <p>By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy.</p>

      <h2>1. Information We Collect</h2>
      <p>Chulesi is a food delivery app that collects the following data to deliver food to the user's doorstep:</p>
      
      <h3>Personal Information:</h3>
      <p>While using our Service, we may ask you to provide certain personally identifiable information, including but not limited to:</p>
      <ul>
        <li>Full Name</li>
        <li>Phone number</li>
        <li>Email</li>
        <li>Delivery address</li>
      </ul>

      <h3>Usage Data:</h3>
      <p>We may collect data on how the Service is accessed and used, such as:</p>
      <ul>
        <li>Device information (e.g., IP address, device type, operating system)</li>
        <li>Log data (e.g., pages visited, time and date of access)</li>
        <li>User location data is only collected upon permission granted by the user, and it is recommended to do so to ensure accurate delivery.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We may use the information we collect for various purposes:</p>
      <ul>
        <li>To process orders and arrange deliveries</li>
        <li>To notify you of updates, changes, or new features of the Service</li>
        <li>To monitor usage and analyze trends to improve user experience</li>
        <li>To provide customer support and respond to inquiries</li>
      </ul>

      <h2>3. Payment Information</h2>
      <p>Since our Service only supports cash on delivery (COD), we do not collect or store any digital payment or banking information.</p>

      <h2>4. Data Sharing and Disclosure</h2>
      <p>We may share your information in the following situations:</p>
      <ul>
        <li>With service providers: We work with third parties who assist with our operations, such as data analytics. These parties have access to your data only to perform these tasks on our behalf and are required to keep your information confidential.</li>
        <li>For legal reasons: We may disclose your information if required to comply with legal obligations, protect our rights, prevent fraud, or respond to requests from legal authorities.</li>
        <li>With your consent: We may share your data for other purposes only with your explicit consent.</li>
      </ul>

      <h2>5. Retention of Personal Data</h2>
      <p>We will retain your personal information only as long as necessary for the purposes set out in this Privacy Policy. Retention periods may vary based on the type of data and legal requirements.</p>

      <h2>6. Security of Your Information</h2>
      <p>To ensure the security of the app, we have implemented email authentication. Once you are logged in to the app, only logged-in users will be able to access all the features of the app. User tokens will be stored in the device's local storage. This token will be used to authorize the user and fetch user data. The token will not expire until the user logs out from the app.</p>
      <p>We take reasonable steps to secure your personal information and protect it from unauthorized access, disclosure, or destruction. However, no method of online transmission or storage is completely secure, and we cannot guarantee absolute security.</p>

      <h2>7. Children’s Privacy</h2>
      <p>Our Service does not target individuals under the age of 13, and we do not knowingly collect personal information from children. If we become aware that we have collected personal data from a child, we will delete it immediately.</p>

      <h2>8. Your Data Rights</h2>
      <p>Depending on your location, you may have the following rights regarding your data:</p>
      <ul>
        <li>Access and update your personal information</li>
        <li>Delete your personal information, subject to legal and contractual obligations</li>
        <li>Withdraw consent to data collection (where applicable)</li>
      </ul>
      <p>To exercise any of these rights, please contact us at <a href="mailto:contact@chulesi.com">contact@chulesi.com</a></p>

      <h2>9. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy periodically to reflect changes in our practices. We will notify you of any significant changes by posting the new Privacy Policy on this page. You are encouraged to review this Privacy Policy regularly for any updates.</p>

      <h2>10. Contact Us</h2>
      <p>If you have questions or concerns regarding this Privacy Policy, you can reach us at:</p>
      <p><strong>Chulesi Food Delivery App</strong><br />
         Chaughada<br />
         Hetauda, 44100<br />
         Phone Number: <a href="tel:+9779862905048">+977 9862905048</a><br />
         Email: <a href="mailto:contact@chulesi.com">contact@chulesi.com</a></p>
    </div>
  );
}

export default PrivacyPolicy;
