import React from 'react';
import './TermsAndCondition.css'

function TermsAndCondition() {
  return (
    <div className='TermsAndCondition'>
           <h1>Terms and Conditions</h1>
  <h2>1. Introduction</h2>
  <p>Welcome to Chulesi! By accessing or using our website or mobile application, you agree to be bound by these Terms and Conditions ("Terms"). Please read them carefully before placing an order. If you do not agree with these Terms, you may not use our services.</p>
  
  <h2>2. Service Availability</h2>
  <p>Chulesi operates as a food delivery service exclusively in Hetauda, Nepal. Our delivery service is available within designated areas of Hetauda, and we reserve the right to refuse service outside this area or due to unforeseen circumstances.</p>

  <h2>3. Account Registration</h2>
  <p>Users can browse food items on the Chulesi platform without creating an account. However, to place an order, you are required to create an account and log in. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Chulesi reserves the right to suspend or terminate accounts found in violation of these Terms.</p>

  <h2>4. Placing Orders</h2>
  <ul>
    <li>You can place an order by selecting food items from our curated menu.</li>
    <li>All orders can be paid using cash on delivery (COD) or through mobile banking apps and digital wallet at the time of delivery.</li>
    <li>Once an order is placed, we will contact our partner restaurants in Hetauda to prepare your food. Chulesi is not responsible for delays caused by the restaurant’s preparation process.</li>
    <li>Orders cannot be modified once confirmed, and any cancellation must be requested as soon as possible. Chulesi reserves the right to accept or reject cancellation requests at its discretion.</li>
    <li>All orders are subject to availability. In the unlikely event that an ordered item is unavailable, we will inform you as soon as possible.</li>
    <li>A base delivery charge of Rs. 75 is applied to each order initially. For every kilometer beyond the initial distance, an additional Rs. 20 will be added.</li>
  </ul>

  <h2>5. Prices and Payment</h2>
  <ul>
    <li>Prices for all food items and the delivery charge are clearly listed on our platform. These prices are subject to change at any time, but such changes will not affect orders already confirmed.</li>
    <li>We only accept cash on delivery (COD) as a form of payment. You are required to pay the full amount, including the delivery charge, in cash to the delivery personnel when receiving your order.</li>
    <li>In case of failed or refused payment at the time of delivery, Chulesi reserves the right to cancel the order and may refuse future services.</li>
  </ul>

  <h2>6. Delivery</h2>
  <ul>
    <li>Chulesi aims to deliver your order within 45 minutes of order confirmation. However, delivery times may vary depending on factors such as traffic, weather, or restaurant preparation times.</li>
    <li>If you are not available at the time of delivery, our delivery personnel will attempt to contact you. Failure to respond or be present may result in the cancellation of the order, and a cancellation fee may apply.</li>
    <li>A base delivery charge of Rs. 75 is applied to each order initially. For every kilometer beyond the initial distance, an additional Rs. 20 will be added, regardless of location within Hetauda.</li>
  </ul>

  <h2>7. Refunds and Cancellations</h2>
  <ul>
    <li>Once an order is confirmed and prepared by the restaurant, it cannot be canceled. Any requests for cancellations or modifications will be reviewed but are not guaranteed.</li>
    <li>Refunds will only be issued if Chulesi determines that the delivered item was significantly different from what was ordered, damaged, or undelivered due to an issue on our part.</li>
  </ul>

  <h2>8. User Conduct</h2>
  <ul>
    <li>You agree to use our platform responsibly and for lawful purposes only. Any misuse of the service, including fraudulent orders, harassment of staff, or violation of these Terms, may result in termination of your account.</li>
    <li>Chulesi reserves the right to take legal action against any individual or entity found to be in violation of these Terms.</li>
  </ul>

  <h2>9. Partner Restaurants</h2>
  <p>Chulesi acts as a facilitator between users and partner restaurants. We are not responsible for the quality, preparation, or safety of the food delivered. Any issues with the food (such as allergens or incorrect ingredients) should be reported directly to Chulesi, and we will assist in resolving them with the restaurant.</p>

  <h2>10. Limitation of Liability</h2>
  <p>Chulesi is not liable for any indirect, incidental, or consequential damages arising from the use of our services. While we strive to offer the best experience, we are not responsible for any delays, errors, or issues beyond our control, such as restaurant delays or technical difficulties.</p>

  <h2>11. Changes to Terms</h2>
  <p>Chulesi reserves the right to modify or update these Terms at any time. Any changes will be posted on our website and app, and your continued use of our services signifies acceptance of the updated Terms.</p>

  <h2>12. Governing Law</h2>
  <p>These Terms shall be governed by and construed in accordance with the laws of Nepal. Any disputes arising under these Terms shall be subject to the exclusive jurisdiction of the courts.</p>

  <h2>13. Contact Us</h2>
  <p>For any questions or concerns regarding these Terms, please contact us at:</p>
  <ul>
    <li>Email: <a href="mailto:contact@chulesi.com">contact@chulesi.com</a></li>
    <li>Phone: <a href="tel:+9779862905048">+977 9862905048</a></li>
  </ul>
    </div>
  );
}

export default TermsAndCondition;
