import React, { useState } from 'react';
import './FAQPage.css';

const FAQPage = () => {
  const faqs = [
    {
      question: "What is Chulesi?",
      answer: "Chulesi is a food delivery service based in Hetauda, Nepal. We allow users to browse a variety of food items through our app and website and place orders that we deliver directly to their doorstep."
    },
    {
      question: "How do I place an order?",
      answer: `
        To place an order on Chulesi:
        1. Open the Chulesi app or website.
        2. Browse or search for the food items you'd like to order.
        3. When youre ready to add items to your cart, you will be prompted to create an account or log in if you haven’t already.
        4. Once your account is set up, you can proceed with adding items to your cart and completing your order.
      `
    },
    {
      question: "Do I need an account to place an order?",
      answer: "Yes, you can browse food items without an account, but you need to create an account or log in when you add an item to your cart. This allows us to manage your orders and provide a personalized experience."
    },
    {
      question: "What information do I need to create an account?",
      answer: `
        To create an account, you will need to provide:
        • Full Name
        • Phone Number
        • Email Address
        • Password
        This information helps us process your orders and ensure accurate delivery.
      `
    },
    {
      question: "What is the delivery charge?",
      answer: "A base delivery charge of Rs. 75 is applied to each order initially. For every kilometer beyond the initial distance, an additional Rs. 20 will be added."
    },
    {
      question: "What is the estimated delivery time?",
      answer: "Our estimated delivery time is 45 minutes, depending on your location and the restaurant’s preparation time. We always strive to deliver as quickly as possible."
    },
    {
      question: "What areas do you deliver to?",
      answer: "Currently, we only deliver within Hetauda, Nepal. We plan to expand our services to other areas soon."
    },
    {
      question: "What payment methods are accepted?",
      answer: "We offer Cash on Delivery (COD) as the only payment method. You will pay the delivery person in cash as well as through mobile banking when your order arrives."
    },
    {
      question: "Can I track my order?",
      answer: "Yes, once your order is placed, we will send you updates via notifications, including when your order is confirmed, being prepared, and out for delivery. Please ensure you have notifications enabled for timely updates."
    },
    {
      question: "Do I need to allow location permissions to use the app?",
      answer: "Yes, you must enable location permissions to use the Chulesi app. We need access to your location to ensure accurate and timely delivery. Without location permissions, certain features of the app may not work as intended."
    },
    {
      question: "What if I have an issue with my order?",
      answer: `
        If you encounter any issues with your order (e.g., wrong items, late delivery), please contact our customer support team through the app or website. 
        You can also reach us via phone or email:
        • Phone: +977 9862905048
        • Email: contact@chulesi.com
      `
    },
    {
      question: "How can I update my delivery address?",
      answer: `
        You can update your delivery address by:
        • Going to your profile within the app or website.
        • Editing your address details in the account settings.
      `
    },
    {
      question: "How can I cancel my order?",
      answer: "To cancel your order, please contact our customer support as soon as possible. Cancellation is only possible before the restaurant starts preparing your food."
    },
    {
      question: "What restaurants do you partner with?",
      answer: "We work with a variety of local restaurants across Hetauda to offer a wide selection of delicious food items. While we don’t display restaurant names in the app, we collaborate with trusted partners to deliver high-quality food."
    },
    {
      question: "Do you offer refunds?",
      answer: "We do not offer refunds once an order is delivered, except in cases of incorrect or incomplete orders. Please contact our customer support for assistance in such situations."
    },
    {
      question: "How do I enable notifications for order updates?",
      answer: `
        To receive important updates about your order, make sure notifications are enabled for the Chulesi app:
        • Android: Go to Settings > Apps > Chulesi > Notifications, and toggle on.
        • iOS: Go to Settings > Notifications > Chulesi, and ensure "Allow Notifications" is on.
      `
    },
    {
      question: "Do you have any promotions or discounts?",
      answer: "We occasionally offer promotions and discounts. Keep notifications enabled or check the app for any special offers."
    }
  ];

  return (
    <div className="faq-page">
      <h1>Frequently Asked Questions</h1>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`faq-item ${isOpen ? 'open' : ''}`}>
      <div className="faq-question" onClick={toggleOpen}>
        {question}
        <span className="faq-icon">{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && <div className="faq-answer">{answer}</div>}
    </div>
  );
};

export default FAQPage;
