import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import necessary components from react-router
import MainPage from "./components/MainPage";
import PrivacyPolicy from "./components/PrivacyPolicy"; // Import the Privacy Policy page
import AboutUs from "./components/AboutUs";
import TermsAndCondition from "./components/TermsAndCondition";
import FAQPage from "./components/FAQPage";
import sitemap from "./components/sitemap.csv"

function App() {
  return (
    <Router>
      <Routes>
        {/* MainPage route: includes all components (header, footer, etc.) */}
        <Route path="/" element={<MainPage />} />

        {/* Privacy Policy route: only shows PrivacyPolicy component */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/about-us" element={<AboutUs/>}/>
        <Route path="/terms-and-condition" element={<TermsAndCondition/>}/>
        <Route path="/faq-page" element={<FAQPage/>}/>
        <Route path="/site-map" element={sitemap}></Route>
      </Routes>
    </Router>
  );
}

export default App;
