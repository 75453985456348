import React from 'react';
import './AboutUs.css';

function AboutUs() {
  return (
    <div className='About-Us'>

<h1>About Us</h1>
    <p>
        Welcome to <strong>Chulesi</strong>, your go-to food delivery service in Hetauda, Nepal! Our mission is to bring the best of local and diverse cuisine right to your doorstep, making food ordering as easy and enjoyable as possible for the people of Hetauda.
    </p>
    
    <p>
        At Chulesi, we offer a seamless experience by allowing you to browse through a handpicked selection of delicious dishes, without the need to choose a restaurant. We’ve partnered with some of the finest eateries in Hetauda to ensure that you get access to a variety of high-quality meals. Whether you're craving a local favorite or something new, all you have to do is select your preferred dishes, place your order, and let us handle the rest.
    </p>
    
    <p>
        Once you order, we directly coordinate with our partner restaurants in Hetauda to prepare your meal, ensuring it’s cooked fresh and delivered to you in a timely manner. You don’t need to worry about contacting multiple restaurants—we’ve got it all covered for you. From the moment you tap 'order' to the time your food reaches your door, Chulesi ensures a hassle-free experience with an emphasis on quality and reliability.
    </p>
    
    <p>
        Our service is designed specifically for the people of Hetauda, making food delivery fast, efficient, and catered to local tastes. Whether you’re at home, at work, or anywhere in the city, Chulesi is here to deliver your favorite meals right when you need them.
    </p>
    
    <p>
        Thank you for choosing <strong>Chulesi</strong>—proudly serving Hetauda, bringing taste and convenience to your doorstep!
    </p>

    </div>
  );
}

export default AboutUs;
